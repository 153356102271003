import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
//import Rightcontainer from '../Components/Rightcontainer'
import '../css/layout.css'
import '../css/login.css'
import loginss from "./ss.png"
import { Box, IconButton, InputAdornment, TextField, Typography, Button, ButtonBase } from '@mui/material'
// import { Button } from 'react-bootstrap/lib/InputGroup'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import axiosInstance from '../../utils/api/Axios'
import Login from './Login'

export default function Register() {

    const history = useHistory()


    const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
    const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
    const nodeMode = process.env.REACT_APP_NODE_ENV;
    if (nodeMode === "development") {
        var baseUrl = '/';
    } else {
        baseUrl = beckendLiveApiUrl;
    }

    const [WebSitesettings, setWebsiteSettings] = useState("");

    const fetchData = async () => {
        const response = await fetch(baseUrl + "settings/data");
        const data = await response.json();
        return setWebsiteSettings(data);
    }
    //console.log(WebSitesettings);
    useEffect(() => {
        fetchData();
    }, [])

    let [currentPage, setCurrentPage] = useState('register');
    const [Phone, setPhone] = useState();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState();
    const [twofactor_code, settwofactor_code] = useState();
    const [otp, setOtp] = useState(false)
    const [secretCode, setSecretCode] = useState();
    const [referral, setReferral] = useState(useLocation().pathname.split("/")[2])

    const handleClick = async (e) => {
        e.preventDefault();

        if (!Phone) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please enter your phone number',

            });

        }
        else if (Phone.length !== 10) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please  enter currect phone number',

            });
        }
        else {
            await axios.post(baseUrl + `login`, {
                Phone, referral
            }).then((respone) => {
                if (respone.data.status === 101) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: respone.data.msg,
                    })
                }
                else if (respone.data.status === 200) {
                    setOtp(true)
                    console.log(respone.data);
                    setSecretCode(respone.data.secret);
                    if (respone.data.myToken) {
                        Swal.fire({
                            icon: 'success',
                            title: 'OTP',
                            text: 'Demo Account Testing OTP: ' + respone.data.myToken,
                        })
                    }
                }
            })
                .catch((e) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong',
                        // width: '20%',
                        // height:'20%',
                    })
                })
        }
    }

    const handleClickModified = async (e) => {

        console.log('Utsav ', Phone, password);
        e.preventDefault();

        if (!Phone) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please enter your phone number',
            });

        }
        else if (Phone.length !== 10) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please  enter currect phone number',

            });
        }
        else if (password.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please enter password',

            });
        }
        else if (password !== confirmPassword) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Passwords do not match',

            });
        }
        else {
            await axios.post(baseUrl + `register`, {
                Phone, referral, password
            }).then(async (respone) => {
                if (respone.data.status === 101) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: respone.data.msg,
                    })
                }
                else if (respone.data.status === 200) {
                    setOtp(true)
                    console.log(respone.data);
                    setSecretCode(respone.data.secret);
                    if (respone.data.secret) {
                        await varifyOtpModified(e);
                        // Swal.fire({
                        //     icon: 'success',
                        //     title: 'OTP',
                        //     text: 'Demo Account Testing OTP: ' + respone.data.myToken,
                        // })
                    }
                }
            })
                .catch((e) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong',
                        // width: '20%',
                        // height:'20%',
                    })
                })
        }
    }


    const varifyOtp = async (e) => {
        e.preventDefault();
        console.log('verify otp sumbut req');
        if (!Phone) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please enter your phone number',

            })
        } else {
            await axios.post(baseUrl + `login/finish`, {

                Phone,
                twofactor_code,
                referral,
                secretCode
            }).then((respone) => {

                if (respone.data.status === 101) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: respone.data.msg,
                    })
                }
                else if (respone.data.status === 200) {

                    const token = respone.data.token
                    localStorage.setItem("token", token)
                    localStorage.setItem("user_id", respone?.data?.user?._id)
                    window.location.reload(true)
                    setTimeout(function () {
                        history.push("/Games")
                    }, 1000);

                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                    })
                }
            })
                .catch((e) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                    })
                })
        }
    }

    const varifyOtpModified = async (e) => {
        e.preventDefault();
        console.log('verify otp sumbut req');
        if (!Phone) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please enter your phone number',

            })
        } else {
            // let baseUrl = process.env.REACT_APP_NODE_ENV == 'development' ? '/' : process.env.REACT_APP_BACKEND_LIVE_API;
            await axiosInstance.post(`/login/finishbypass`, {
                Phone
            }).then((respone) => {
                if (respone.data.status === 101) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: respone.data.msg,
                    })
                }
                else if (respone.data.status === 200) {

                    const token = respone.data.token
                    localStorage.setItem("token", token)
                    localStorage.setItem("user_id", respone?.data?.user?._id)
                    window.location.reload(true)
                    setTimeout(function () {
                        history.push("/Games")
                    }, 1000);
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                    })
                }
            })
                .catch((e) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                    })
                })
        }
    }
    const setError = () => {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Invalid Number',
            confirmation: true,
        })
    }


    if (currentPage == 'login') {
        return <Login />
    }

    return (
        <>
            {/* <div className='leftContainer' style={{ minHeight: '100vh' }}>
                <div className="main-area bg-dark">
                    <div style={{ overflowY: 'hidden' }}>
                        <div className="splash-overlay" />
                        <div className="splash-screen animate__bounce infinite ">
                            <figure><img width="100%" src={loginss} style={{width: "auto"}} alt="" /></figure>
                        </div>
                        <div className="position-absolute w-100 center-xy mx-auto" style={{ top: '30%', zIndex: 9 }}>
                            <div className="d-flex text-white font-15 mb-4">Sign in</div>
                            <div className="bg-white px-3 cxy flex-column" style={{
                                width: '85%', height: '60px', borderRadius: '5px'
                            }}>
                                <div className="input-group mb-2 " style={{ transition: 'top 0.5s ease 0s', top: '5px' }}>
                                    <div className="input-group-prepend">
                                        <div className="input-group-text" style={{ width: '100px', backgroundColor: '#e9ecef', border: '1px solid #d8d6de' }}>+91</div>
                                    </div>
                                    <input className="form-control" name="mobile" type="tel" placeholder="Mobile number"
                                        // onChange={(e) => setPhone(e.target.value)}
                                        onChange={(e) => {
                                            setPhone(e.target.value);
                                            if (e.target.value.length > 10) {
                                                setError(true)
                                            }
                                        }}
                                        style={{ transition: 'all 3s ease-out 0s', borderRadius: "4px" }}
                                    />
                                    
                                </div>

                            </div>
                            {otp && <div className="bg-white px-3 cxy flex-column" style={{
                                width: '85%', height: '60px', borderRadius: '5px', marginTop: "10px"
                            }}>

                                <div className="input-group mb-2" style={{ transition: 'top 0.5s ease 0s', top: '5px' }}>
                                    <div className="input-group-prepend">
                                        <div className="input-group-text" style={{ width: '100px', backgroundColor: '#e9ecef', border: '1px solid #d8d6de' }}>OTP</div>
                                    </div>
                                    <input className="form-control" name="password" type="tel" placeholder="Enter OTP"
                                        onChange={(e) => settwofactor_code(e.target.value)}
                                        style={{ transition: 'all 3s ease-out 0s', borderRadius: "4px", border: '1px solid #d8d6de' }} />
                                    </div>

                            </div>}
                            {!otp && <button className="Login-button cxy mt-4" onClick={(e) => handleClick(e)} >Continue
                            </button>}
                            {otp && <button className="Login-button cxy mt-4" onClick={(e) => varifyOtp(e)} >Verify
                            </button>}

                        </div>
                        <div className="login-footer">By continuing I agree that {(WebSitesettings) ? WebSitesettings.CompanyName : ''}. may store and process my data in accordance with the <Link to="/term-condition">Terms of Use</Link>, <Link to="/PrivacyPolicy">Privacy Policy</Link> and that I am 18 years or older. I am not playing from
                            Assam, Odisha, Nagaland, Sikkim, Meghalaya, Andhra Pradesh, or Telangana.
                        </div>
                    </div>
                </div>

            </div> */}
            <div className='leftContainer'>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "start",
                        height: "100vh",
                        background: "linear-gradient(15deg, #382207, #653e02)",
                        color: "#fff",
                    }}
                >
                    {/* Logo Section */}
                    {/* <Typography
        variant="h4"
        fontWeight="bold"
        sx={{ mb: 4, textAlign: "center" }}
      >
        R.D Ludo
      </Typography>
      <Typography variant="h6" sx={{ mb: 4, textAlign: "center" }}>
        Play | Earn | Enjoy
      </Typography> */}

                    {/* Login Section */}
                    <Box
                        sx={{
                            mt: 12,
                            backgroundColor: "linear-gradient(15deg, #382207, #653e02)",
                            padding: "20px",
                            borderRadius: "10px",
                            //   boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
                            width: { xs: "90%", sm: "400px" },
                        }}
                    >
                        <Typography
                            variant="h4"
                            textAlign="center"
                            fontWeight="bold"
                            sx={{ mb: 2 }}
                        >
                            Register
                        </Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Enter Your Mobile Number"
                            sx={{
                                background: "#fff",
                                borderRadius: "5px",
                                mb: 2,
                            }}
                            value={Phone}
                            onChange={(e) => {
                                setPhone(e.target.value);
                                console.log('value', e.target.value);
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {/* <PhoneIcon /> */}
                                        {/* <LocalPhoneIcon/> */}
                                        <span> +91</span>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type='password'
                            placeholder="Enter your password"
                            value={password}
                            required
                            onChange={(e) => {
                                setPassword(e.target.value);
                                console.log('value', e.target.value);
                            }}
                            sx={{
                                background: "#fff",
                                borderRadius: "5px",
                                mb: 2,
                            }}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type='password'
                            placeholder="confirm your password"
                            //   value={password}
                            value={confirmPassword}
                            onChange={(e) => {
                                setConfirmPassword(e.target.value);

                            }}
                            required
                            //   onChange={(e)=>{
                            //     setPassword(e.target.value);
                            //     console.log('value', e.target.value);
                            //   }}
                            sx={{
                                background: "#fff",
                                borderRadius: "5px",
                                mb: 2,
                            }}
                        />
                        <Button
                            fullWidth
                            onClick={(e) => {
                                handleClickModified(e);
                            }}
                            variant="contained"
                            sx={{
                                mb: 2,
                                background: "linear-gradient(45deg, #dd8f22, #fbc904)",
                                color: "white",
                                fontWeight: "bold",
                                ":hover": { background: "linear-gradient(45deg, #dd8f24, #fbc904)" },
                            }}
                        >
                            Register
                        </Button>
                        <Typography
                            variant="body2"
                            style={{ textAlign: 'left', display: 'flex', gap: 10 }}
                            sx={{ pt: 5, fontSize: "12px", color: "#ddd", textAlign: "center" }}
                        >
                            Already Registered? <span> <ButtonBase onClick={() => { setCurrentPage('login') }} ><Typography variant='body2' style={{ color: 'wheat', cursor: 'pointer' }}> Login here</Typography></ButtonBase> </span>
                        </Typography>
                        <Typography
                            variant="body2"
                            style={{ textAlign: 'left' }}
                            sx={{ pt: 5, fontSize: "12px", color: "#ddd", textAlign: "center" }}
                        >
                            By continuing I agree that WinLudo GAMING PRIVATE LIMITED. may store and process my data in accordance with the Terms of Use, Privacy Policy and that I am 18 years or older. I am not playing from Assam, Odisha, Nagaland, Sikkim, Meghalaya, Andhra Pradesh, or Telangana.
                        </Typography>
                    </Box>

                    {/* Footer Icons */}
                    {/* <Box
        sx={{
          display: "flex",
          gap: 2,
          mt: 3,
        }}
      >
        <IconButton color="inherit">
          <i className="fab fa-facebook"></i>
        </IconButton>
        <IconButton color="inherit">
          <i className="fab fa-linkedin"></i>
        </IconButton>
        <IconButton color="inherit">
          <i className="fab fa-twitter"></i>
        </IconButton>
        <IconButton color="inherit">
        </IconButton>
      </Box> */}
                </Box>
            </div>
        </>
    )
}