import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_LOCAL_API,
  // baseURL: '/',
});

let token = localStorage.getItem("token");

axiosInstance.interceptors.request.use((config) => {
  const accessToken = token;

  if (accessToken) {
    config.headers["Authorization"] = `Bearer ${accessToken || ""}`;
  }
  return config;
});

// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("access_token");
//   config.params = config.params || {};
//   config.params["auth"] = token;
//   return config;
// });

export default axiosInstance;
